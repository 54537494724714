
import { Component, Vue } from "vue-property-decorator";

@Component
export default class WelcomePage extends Vue {
  get welcomeContent() {
    return this.appData.welcome.translation?.description;
  }

  get appData() {
    return this.$store.getters["app/data"];
  }

  created() {
    if (!this.welcomeContent) {
      this.$router.push({ name: "login" }).catch(e => e);
    }
  }
}
